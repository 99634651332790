@import "../../../styles/base.scss";

.AbroadDropdown {
  [class^="ExpandableSection-titleWrapper"] {
    outline: none;
  }

  [class^="Panel-titleBar"] {
    min-height: unset;
    background: transparent !important;
    padding-bottom: 20px;

    [class^="ExpandableSection-title"] {
      font-family: $bbc-font;
      font-size: 24px;
      color: $white;
      text-transform: capitalize;
      font-weight: normal;

    }

    [class^="ExpandableSection-caret"] {
      font-size: 0;

      &::after {
        content: url("../../../assets/images/arrow.svg");
      }

      &[class*="collapsed"] {
        top: 3px;
        transform: scale(0.8) rotate(-90deg);
      }
      &[class*="expanded"] {
        top: 4px;
        transform: scale(0.8) rotate(90deg);
      }
    }
  }

  [class^="ExpandableSection-innerContent"] {
    font-family: $bbc-font;
    color: $white;
    text-align: left;
    font-size: 20px;

    li {
      padding-left: 20px;
      line-height: 36px;
    }
  }
}

@media screen and (max-width: $breakpoint3) {
  .AbroadDropdown {
    [class^="Panel-titleBar"] [class^="ExpandableSection-title"] {
      font-family: $bbc-font;
      font-size: 20px;
    }

    [class^="ExpandableSection-innerContent"] {
      font-size: 16px;
      padding: 0;

      li {
        padding-left: 10px;
        line-height: 30px;
      }
    }
  }
}
