@import "../../styles/base.scss";

.AnimatedLiveButton {
    position: absolute;
    right: 16px;
    top: 11.102px;
    width: 60px;
    height: 15.299px;
}

#live-dot {
    path {
        animation: pulseAnimation 2s infinite;
    }
}


@keyframes pulseAnimation {
    0% {
        fill: $aqua;
    }
    50% {
        fill: $dark-aqua;
    }
    100% {
        fill: $aqua;
    }
  }
  
