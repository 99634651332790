@import "../../../../styles/base.scss";

.vjs-endscreen {
  .Carousel {
    position: absolute;
    bottom: 0;
    min-width: 100%;
    background-color: $black-alpha85;
  }
}
