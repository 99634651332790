@import "../../styles/base.scss";

.Tile {
  width: 24%;
  display: inline-block;
  font-family: $bbc-font;
  white-space: normal;
  vertical-align: top;
  line-height: normal;

  a, a:visited, a:active {
    color: $light-grey;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
  }

  &__image {
    width: 100%;
    margin-bottom: 8px;
    position: relative;
    cursor: pointer;
    padding-bottom: calc(9/16 * 100%);
    overflow: hidden;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  &__header {
    font-size: 16px;
    font-weight: bold;
    color: $light-grey;
    margin-bottom: 4px;
    cursor: pointer;

    &.episode {
      cursor: pointer;
    }
  }
  &__meta1 {
    font-size: 14px;
    color: $light-grey;
    margin-bottom: 2px;
  }
  &__meta2 {
    font-size: 14px;
    color: $mid-grey-2;
  }

  &:hover {
    .img {
      opacity: 0.7;
    }
    a:hover {
      text-decoration: none;
      span {
        color: $iPlayer-Pink-Logo;
        transition: color 0.3s ease-in-out;
      }
    }
    a:focus { 
      text-decoration: none;
      span {
        color: $dark-pink;
        transition: color 0.3s ease-in-out;
      }
    }
    span:hover {
      color: $iPlayer-Pink-Logo;
      transition: color 0.3s ease-in-out;
    }
    span:active {
      color: $pink;
      transition: color 0.3s ease-in-out;
    }
   
  }
}

@media screen and (max-width: 1224px) {
  .Tile {
    width: 24%;
  }
}

@media screen and (max-width: $breakpoint4) {
  .Tile {
    width: 31%;
  }
}

@media screen and (max-width: $breakpoint3) {
  .Tile {
    &__meta1, &__meta2 {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: $breakpoint1) {
  .Tile {
    width: 153px;

    .ProgressBar {
      height: 3px;
    }
  }
}
