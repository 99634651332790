@import "./fonts.scss";

// Colors
$black: black;
$pink: #CD2874;
$lightest-gray: #B0B2B4;
$black-alpha80: rgba(0, 0, 0, 0.8);
$black-alpha85: rgba(0, 0, 0, 0.85);
$almost-black: #111111;
$almost-black-alpha30: rgba(17, 17, 17, 0.3);
$almost-black-alpha85: rgba(17, 17, 17, 0.85);
$almost-black-alpha90: rgba(17, 17, 17, 0.9);
$dark-grey: #222222;
$divider-grey: #232323;
$divider-grey-alpha85: rgba(35, 35, 35, 0.85);
$box-grey: #2f302e;
$box-grey-alpha85: rgba(47,48,46, 0.85);
$mid-grey: #666666;
$mid-grey-2: #747478;
$light-grey: #cccccc;
$lightest-grey: #e9e9e9;
$darkest-blue: #014D66;
$darker-blue: #007197;
$dark-blue: #027fa9;
$mid-blue: #0396c8;
$mid-blue-2: #009bcb;
$light-blue: #67cddd;
$blue: #55c4f5;
$white: white;
$white-alpha10: rgba(255, 255, 255, 0.1);
$white-alpha60: rgba(255, 255, 255, 0.6);
$white-alpha80: rgba(255, 255, 255, 0.8);
$success-green: #028800;
$error-red: #a6231c;
$red: #e4134f;
$singtel-hover-red: #d52f37;
$starhub-hover-green : #1ed760;
$solid-white : #f2f2f2;
$singtel-border-red : #a6231c;
$starhub-border-green : #27ae60;
$iPlayer-Pink-Logo: #FF4C98;
$aqua: #00BFBA;
$dark-aqua: #082625;
$dark-pink: #A2225D;
// Breakpoints
$breakpoint1: 375px;
$breakpoint2: 650px;
$breakpoint3: 768px;
$breakpoint4: 1008px;
$breakpoint5: 1280px;

// Styles
$spacing-small: 8px;
$spacing-large: 16px;

.scroll-lock, .error {
  overflow-y: hidden;
}

.error.scrollable {
  overflow: visible;
}

.tab-selector {
  width: 100%;
  height: 4px;
  background-color: $white;
  position: absolute;
  left: 0;
  bottom: 0;
  display: none;
}

.nodisplay {
  display: none !important;
}

%generic-link {
  a {
    text-decoration: none;
    color: $light-grey;

    &:hover, &:focus {
      text-decoration: underline;
      color: $iPlayer-Pink-Logo;
    }
  }
}

@media screen and (max-width: $breakpoint2) {
  .mobileonly {
    display: inherit;
  }
  .non-mobileonly {
    display: none
  }
}
@media screen and (min-width: $breakpoint2) {
  .mobileonly {
    display: none;
  }
  .non-mobileonly {
    display: inherit;
  }
}

// Fonts
$bbc-font: BBCReithSans, sans-serif;

// Mixins
@mixin equal-dimensions($value) {
  height: $value;
  width: $value;
}

@mixin all-widths($value) {
  min-width: $value;
  width: $value;
  max-width: $value;
}

@mixin all-heights($value) {
  min-height: $value;
  height: $value;
  max-height: $value;
}

// Animations
@keyframes spin {
  0%  {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}
