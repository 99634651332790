@import "./styles/base.scss";

html, body, #root {
  height: 100%;
  width: 100%;
  background-color: $almost-black;
}

body.searching {
  overflow-y: hidden;
}

body:not(.modal-open) {
  overflow-y: scroll !important;
}

.error {
  .App {
    display: none;
  }
}

.App {
  background-color: $almost-black;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  &.settings, &.terms-and-conditions, &.help-and-support, &.about {
    background-color: $dark-grey;
  }
}

.app-body-overlay {
  position: fixed;
  top: 135px;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background:black; 
  z-index: 1000;
  animation: overlayAnimation 500ms ease-in-out;

  &.live {
    top: 363px;
  }
}

@keyframes overlayAnimation {
  0% {
    opacity: 0;
  } 
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.5;
  }
}

.app-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: $breakpoint5;
  margin: 0 auto;
  position: relative;

}

.displayNone {
  display: none !important;
}

.branch-banner-is-active {
  &.searching {
    overflow: hidden;

    .App {
      overflow: hidden;
      max-height: 100%;
    }

    .SearchResults__content {
      // height: calc(100vh - 71px - 86px);
      height: 100%;
    }
  }

  #root {
    position: fixed;
    overflow-y: auto;
    height: calc(100% - 86px);
  }
}

@media screen and (min-width: $breakpoint2) and (max-width: $breakpoint3) {
  .SubMenu {
    &.open {
      top: calc(51px + 86px);
    }
  }
  .app-body-overlay {
    top: calc(50px + 64px);
  
    &.live {
      top: calc(50px + 293px);
    }
  }
}

@media screen and (max-width: $breakpoint2) {
  .branch-banner-is-active {
    overflow-y: hidden;

    #root {
      overflow-y: auto;
      height: 100%;
    }

    .App {
      padding-bottom: 86px;
    }

    .SubMenu {
      &.open {
        top: auto !important;
      }
    }

    &.searching {
      #root {
        overflow: hidden;
      }
      .SearchResults {
        height: calc(100vh - 188px);
      }
    }
  }
}
