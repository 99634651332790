@import "../../styles/base.scss";

.Tv_Guide {
    background: $almost-black;
    width: 100%;

    &_Header {
        width: 100%;
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        border-bottom: 1px solid $divider-grey;
        gap: 10px;

        span {
            color: $lightest-grey;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: $bbc-font;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 36px;
            padding: 10px;
        }

    }

    &_Channels_List {
        width: inherit;
        display: flex;
        padding: 8px 0px;
        justify-content: center;
        align-items: center;
        gap: 32px;
        border-bottom: 1px solid $divider-grey;
    }

    &_Image {
        width: 100px;
        height: 40px;
        flex-shrink: 0;
        overflow: hidden;
        align-items: center;
        display: inline-flex;
        img {
            width: 170%;
            height: 170%;
        }
    }
}

@media screen and (max-width: $breakpoint2) {
    .Tv_Guide {
        &_Channels_List {
            padding: 0px;
        }

        &_Image {
            width: 100%;
            height: inherit;
            flex-shrink: 0;
        }

        &_left_arrow {
            position: relative;
            z-index: 100;
            left: 65px;
            padding: 2px;
            cursor: pointer;
        }

        &_right_arrow {
            z-index: 100;
            position: relative;
            right: 65px;
            padding: 2px;
            cursor: pointer;
        }
    }
}

@media screen and (min-width: $breakpoint2) {
    .Tv_Guide {
        &_Image { 
          cursor: pointer;
        }
    }
  }