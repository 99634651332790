@import "../../styles/base.scss";

.SubMenuTab {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  position: relative;
  font-family: $bbc-font;
  font-size: 14px;
  background-color: $dark-grey;
  height: 48px;
  padding: 8px;
  grid-row: 1;

  &.image {
    height: 48px;
    padding: 8px;
    max-width: max-content;
    justify-self: center;

    img {
      height: 48px;
      transition: opacity 0.5s ease-in-out;
    }


  }

  &, > span, > img {
    cursor: pointer;
  }

  &:hover {
    color: $iPlayer-Pink-Logo;
    transition: background-color 0.5s ease-in,0.5s ease-out;
    transition: color 0.5s ease-in,0.5s ease-out;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
  }

  &__arrow {
    font-size: 6px;
    margin-left: 6px;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 6px;
  }

  &.selected {
    color: $iPlayer-Pink-Logo;
    transition: color 0.5s ease-in,0.5s ease-out;
  }
}

@media screen and (min-width: $breakpoint2) {
  .scroll-up {
    .SubMenuTab {
      background-color: transparent;

      &:hover {
        color: $iPlayer-Pink-Logo;
        transition: background-color 0.5s ease-in,0.5s ease-out;
        transition: color 0.5s ease-in,0.5s ease-out;
      }
    }
  }
}

@media screen and (min-width: $breakpoint2) and (max-width: $breakpoint4) {
  .SubMenuTab {
    height: 32px;
  }
}
