@import "../../styles/base.scss";

.Series {
  color: $white;
  font-family: $bbc-font;
  border-bottom: 1px solid $divider-grey;

  &__detail {
    display: grid;
    grid-template-columns: 415px auto;
    height: 347px;
    position: relative;
    padding: 0px $spacing-large;

    > div {
      display: inline-block;
    }

    .Series__title {
      margin-top: 16px;
      font-size: 24px;
      font-weight: bold;
      color: $lightest-grey;
    }

    .Series__subtitle {
      margin-top: 8px;
      font-size: 13px;
      color: $light-grey;
    }

    .Series__description {
      margin-top: 8px;
      font-size: 14px;
      color: $light-grey;
    }

    .Series__remove {
      cursor: pointer;
      margin-top: 15px;
      font-size: 15px;
      font-weight: 500;
      color: $pink;
      svg {
        width: 12px;
        height: 12px;
        margin-right: 7px;
        path {
          fill: $pink;
        }
      }
    }

    .Series__watchlist-channel {
      display: inline-block;

      > div {
        display: block
      }
    }

    .Series__channel-logo {
      margin-top: 15px;
      img {
        height: 40px;
      }
    }

    .Series__poster {
      height: 100%;
      position: absolute;
      right: 121px;
      min-width: calc(16/9 * 347px);
    }
  }

  & &__seasons {
    padding: 0 $spacing-large;

    &-tablist {
      height: 48px;
      background-color: $almost-black;
      border-top: 1px solid $dark-grey;
      border-bottom: 1px solid $dark-grey;
      margin-bottom: 0;

      div {
        border-bottom: none !important;
      }

      &-head {
        pointer-events: none;
        @include all-widths(85px);
        font-size: 16px;
        font-weight: bold;
        line-height: 1.6;
        color: $mid-grey-2;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        margin-right: 0;
      }

      &-tab {
        width: 35px;
        font-size: 16px;
        font-weight: bold;
        line-height: unset;
        cursor: pointer;
        color: rgba(255, 255, 255, 0.87);
        transition: color 0.3s ease-in-out,
        border-bottom 0.3s ease-in-out;
        &[aria-selected="true"] {
          color: $iPlayer-Pink-Logo;
          border-bottom: 4px solid $iPlayer-Pink-Logo !important;
        }

        &:focus {
          outline: none;
        }

        &:hover {
          background-color: $dark-grey;
        }
      }
    }

    .Series__episodes_list {
      padding: 0px 211px;
    }

    .Series__tab-panel {
      margin-bottom: auto;
      &:focus {
        outline: none;
      }
    }
  }
}

@media screen and (min-width: $breakpoint4) and (max-width: $breakpoint5) {
  .Series {
    &__detail {
      grid-template-columns: 400px auto;
      height: 270px;

      .Series__poster {
        right: 100px;
        min-width: calc(16/9 * 270px);
      }
    }

    &__seasons {
      .Series__episodes_list {
        padding: 0 85px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint4) {
  .Series {
    & &__detail {
      height: 237px;
      grid-template-columns: 300px auto;

      .Series__watchlist-channel {
        display: flex;

        > div {
          display: inline;
        }

        // .Series__channel-logo {
        //   margin-left: auto;
        // }
      }

      .Series__poster {
        right: 16px;
        min-width: calc(16/9 * 237px);
      }
    }

    & &__metadata {
      max-width: 300px;
    }

    & &__seasons {
      .Series__episodes_list {
        padding: 0;
      }
    }
  }

}

@media screen and (max-width: $breakpoint3) {
  .Series {
    & &__detail {
      height: auto;
      grid-template-columns: 1fr;
      grid-template-rows: calc(9/16 * 100vw) auto 1fr;
      padding: 0;

      > div {
        grid-column: 1;
      }

      .Series__poster {
        grid-row: 1;
        display: block;
        width: 100%;
        min-width: auto;
        position: initial;
      }
    }

    & &__metadata {
      grid-row: 2;
      display: block;
      margin: 0;
      padding: 0 $spacing-small 16px $spacing-small;
      max-width: 100%;
    }

    & &__seasons {
      padding: 0 $spacing-small;

      &-tablist-tab[aria-selected="true"] {
        background-color: $dark-grey;
      }
    }
  }
}
