@import "../../styles/base.scss";

.abroad-alert{    
    background: white !important;
    display: flex;
    justify-content: center;
    padding: 4px;
    text-align: center;
    font-size: 14px;
    color: black;
  }
  .warning-img{
    vertical-align:middle
  }
  .warning-msg{
    vertical-align:middle
  }