@import "../../../styles/base.scss";

.VideoSettings {
  display: grid;
  grid-template-columns: 166px auto;
  grid-template-rows: repeat(6, auto);
  align-items: flex-start;
  font-family: $bbc-font;
  grid-row-gap: 24px;
  align-items: center;
  position: relative;

  &__header {
    grid-column: 1 / 3;
    font-weight: 300;
    font-size: 20px;
    color: $lightest-grey;
  }
  &__label {
    font-weight: 300;
    font-size: 16px;
    color: $light-grey;
  }
  &__content {
    width: 100%;

    button {
      margin-right: 18px;
      margin-bottom: 18px;
      @include all-widths(152px);
    }
  }
  .quality {
    align-self: center;
  }

  .autoplay {
    > div:last-child {
      margin-top: 8px;
      font-weight: 300;
      color: $lightest-grey;
      font-size: 16px;
      line-height: 20px;
    }
  }

  .audio-menu {
    padding-bottom: 22px;
    border-bottom: 1px solid $almost-black;
  }

  .language {
    align-self: center;
  }

.disable_toggle {
  opacity: 0.5;
  pointer-events: none;
}
}
