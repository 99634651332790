@import "../../styles/base.scss";

.LiveSubMenuTab {
    display: flex;
    padding: 20px 0px;
    flex-direction: column;
    align-items: center;
    gap: 34px;
    justify-content: center;
    color: $white;
    position: relative;
    font-family: $bbc-font;
    font-size: 16px;
    background-color: $dark-grey;

  &.image {

    padding: 8px;
    max-width: min-content;
    justify-self: center;

    img {
      height: 48px;
    }

    .tab-selector {
      display: none;
    }

    &.selected {
      background-color: $white;
    }
  }


  &__arrow {
    font-size: 6px;
    margin-left: 6px;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 6px;
  }

  &.selected .tab-selector {
    display: block;
  }
}

.LiveSubMenu {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 5px;
    font-style: normal;
    font-family: $bbc-font;

    &_Card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 3px;

        &_Logo {
          display: flex;
          flex-direction: column;
        }

        &_Image {
          width: 212px;
          height: 120px;
          background: $black;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }

        &_CL {
          overflow: hidden;
        }

        &_BWImage {
          width: 100%;
          height: 100%;
          background-image: none;
        }

        &_CLImage {
          width: 105%;
          height: 125%;
          background-image: none;
        }

        &_Progress {
          height: 4px !important;
          position: relative!important;
          background-color: $lightest-grey;
          transition: background-color 0.5s ease-in,0.5s ease-out;
        }
  
        &_Title {
          color: $white;
          width: 212px;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 22px;
          cursor: pointer;
          &.truncate{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
          }    

          &.hover {
            color: $iPlayer-Pink-Logo;
            transition: color 0.5s ease-in,0.5s ease-out;
          }
        }
  
        &_Schedule {
            color: $light-grey;
            font-feature-settings: 'clig' off, 'liga' off;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px; 

            &.hover {
              color: $lightest-grey;
              transition: color 0.5s ease-in,0.5s ease-out;
            }
        }

    }

    
}

.TvGuideButton {
    cursor: pointer;
    display: flex;
    width: 406px;
    height: 48px;
    justify-content: center;
    align-items: center;
    color: $lightest-grey;
    text-align: center;
    font-family: $bbc-font;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; 
    background-color: inherit;
    border: 1px solid $light-grey;
    flex-shrink: 0;

    &:hover {
        color: $white;
        transition: color 0.5s ease-in,0.5s ease-out;
        border: 2px solid $lightest-grey;
    }

    &.selected {
      color: $white;
      transition: color 0.5s ease-in,0.5s ease-out;
      border: 2px solid $white;
    }
}

@media screen and (min-width: $breakpoint2) and (max-width: $breakpoint4) {
  .LiveSubMenuTab {
    min-width: 100%!important;
  }

  .LiveSubMenu_Card_Title {
    font-size: 14px;
  }

  .TvGuideButton {
    width: 203px;
    font-size: 14px;
  }
}
