@import "../../styles/base.scss";

.Live {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    width: inherit;
    margin: 0 15px;
    border-bottom: 1px solid $divider-grey;

    &_Border {
        display: flex;
        flex: 1;
        width: auto;
        height: 262px;
        border-right: 1px solid $divider-grey;

        &.last {
            border-right: none;
        }
    }

    &_Card {
        flex: 1;
        display: flex;
        padding: 20px;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        gap: 8px;
        font-family: $bbc-font;
        font-style: normal;
    }

    &_Heading {
        width: inherit;
        display: inline-flex;
        gap: 2px;
        color: $white;
        font-size: 12px;
        line-height: 16px;
    }

    &_Type {
        font-weight: 700;
    }

    &_Time {
        font-weight: 400;
        font-feature-settings: 'clig' off, 'liga' off;
    } 

    &_Divider {
        width: 20px;
        padding: 5px 0px;
        stroke-width: 1px;
    }

    &_PrgrammeTitle {
        display: flex;
        padding: 2px 5px 2px 0px;
        align-items: center;
        color: $white;

        span {
            
            font-feature-settings: 'clig' off, 'liga' off;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px; 
        }

        &.selected {
            color: $pink;
        }

        &:hover {
            &.hover {
                color: $iPlayer-Pink-Logo;
                cursor: pointer;
            }
        }
    }

    &_Episode {
        color: $light-grey;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
    }

    &_Description {
        display: flex;
        width: 488px;
        flex-direction: column;
        justify-content: center;
        align-self: stretch;
        height: 60px;
        span {
            width: inherit;
            height: inherit;
            color: $white;
            font-feature-settings: 'clig' off, 'liga' off;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px; 
        }

    }

    &_Schedule_Button {
        position: relative;
        top: 60px;
        align-items: baseline;
    }

    &_Play_Button {
        margin: 0px 0px 8px 0px;

        &.selected, &:hover {
            background: $lightest-grey;
            border: 3px solid $black !important;
            svg {
                g {
                    path {
                        fill: $black !important;
                    }
                }
            }
            span {
                color: $black;
            }
        }
    }

}

@media screen and (max-width: $breakpoint2) {
    .Live { 
        flex-direction: column;
        border-bottom: none;
        margin: 0 8px;
        
        &_Border {
            width: 100%;
            border-right: none;
            border-bottom: 1px solid $divider-grey;
        }

        &_Card {
            width: 100%;
            padding: 10px 0;
        }

        &_Description {
            width: auto;
            height: 54px;
            span { 
                width: auto;
                height: 54px;
            }
        }

        &_BlackDivider {
            position: relative !important;
            top: 5px;
            div {
                margin: 0 15px 0 0;
            }
        }

        &_Schedule_Button {
            top : -5px;
        }
    }
  }
  
  @media screen and (min-width: $breakpoint2) and (max-width: $breakpoint4) {
    .Live { 
        &_Heading {
            font-size: 13px;
        }

        &_PrgrammeTitle {
            span {
                font-size: 18px;
            }
        }

        &_Schedule_Button {
            top: 30px;
        }
    }
  }