@import "../../../styles/base.scss";

.MobileSubMenu {
  width: 100%;
  background: $black;

  &__header {
    width: 100%;
    background-color: $black;
    height: 51px;
    color: $mid-grey-2;
    font-family: $bbc-font;
    font-size: 18px;
    display: flex;
    gap:15px;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    align-content: center;
    border-bottom: 1px solid $mid-grey-2;

    img {
      height: 16px;
      cursor: pointer;
    }

    &-back {
      justify-self: left;
      padding-left: $spacing-large;

      img {
        transform: scaleX(-1);
      }

      &.hidden {
        visibility: hidden;
      }
    }
    &-title {
      align-self: center;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $white;
      border-left: 1px solid $divider-grey;
      border-right: 1px solid $divider-grey;
      padding: 0px 16px;
      height: inherit;
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: $bbc-font;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; 
      cursor: pointer;
    }
    &-close {
      align-self: left;
      padding-right: $spacing-large;
      padding-top:  $spacing-large;
    }
  }

  &__contents {
    background-color: $black;
    &.images {
      display: grid;
      grid-template-columns: repeat(3, minmax(90px, 1fr));
      padding: 11px $spacing-small;
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    &__title {
      color: $white;
      font-family: $bbc-font;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      padding: 16px 0px 0px 10px;
    }

    &__body {
      display: flex;
      width: 100%;
      align-items: center;
      gap: 10px;
      background-color: $dark-grey;
      overflow-x: auto;
      overflow-y: hidden;

      &.Center {
        justify-content: center;
      }
    }
  }
}
