@import "../../../../styles/base.scss";

.PlayerLoadingOverlay {
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  justify-content: center;
  background: $black;
  top: 0;
  left: 0;
  z-index: 1001;

  &__loading {
    @include equal-dimensions(48px);
    animation: spin 1.5s infinite linear;
    position: absolute;
    align-self: center;
  }
}
