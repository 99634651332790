@import "../../styles/base.scss";

.Carousel {
  .Scroller {
    position: absolute;
    z-index: 10;
    right: $spacing-large;
    top: 30.5%;

    > div {
      display: inline-flex;
      background-color: $white;
      padding: 16px;
      margin-left: 2px;
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;

      &:hover {
        background-color: $iPlayer-Pink-Logo;
      }

      &.disabled {
        &, svg {
          background-color: $white;
          opacity: 0.2;
          pointer-events: none;
        }
      }
    }

    &__left {
      transform: scaleX(-1);
    }

    svg {
      path {
        fill: $almost-black;
      }
      @include equal-dimensions(16px);
      position: initial;
    }
  }
}
