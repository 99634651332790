@import "../../styles/base.scss";

.SearchResults {
  position: relative;
  width: 100%;
  background-color: $almost-black;
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  flex-grow: 1;

  &__open {
    overflow-y: hidden;
    height: 100%;
    width: 100%;
  }

  &__content {
    position: relative;
    max-width: $breakpoint5;
  }

  &__header {
    font-family: $bbc-font;
    font-size: 22px;
    color: $light-grey;
    padding: 24px $spacing-large;

    > span {
      color: $white;
      font-weight: bold;
    }
  }

  &__error {
    padding: $spacing-large;
    font-family: $bbc-font;
    font-size: 18px;
    color: $white;
    position: relative;

    div:first-child {
      font-weight: bold;
    }
  }

  &__grid {
    padding: 0 $spacing-large $spacing-large $spacing-large;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(4, .25fr);

    .Tile {
      margin-right: 8px;
      width: 100%;
    }
  }
}

@media screen and (max-width: $breakpoint2) {
  .SearchResults {
    top: 50px!important;
    margin-bottom: 50px;
    &__grid {
      grid-template-columns: repeat(2, .5fr);
      padding: $spacing-small;
    }
    &__header {
      display: none
    }
    &__error {
      font-size: 16px;
      padding: $spacing-small;
    }
  }
}

@media screen and (min-width: $breakpoint2) and (max-width: $breakpoint3) {
  .SearchResults {
    // top: 51px;
    // height: calc(100vh - 51px);

    &__header {
      font-size: 22px;
    }
    &__error {
      font-size: 18px;
    }
  }
}

@media screen and (max-width: $breakpoint5) {
  .SearchResults {
    &__content {
      min-width: 100%;
    }
  }
}

@media screen and (min-width: $breakpoint5) {
  .SearchResults {
    &__content {
      min-width: $breakpoint5;
    }
  }
}
