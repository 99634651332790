@import "../../styles/base.scss";

.Settings {
  background-color: $dark-grey;
  display: grid;
  grid-template-columns: 168px auto;
  font-family: $bbc-font;
  border-bottom: 1px solid $divider-grey;

  &__menu {
    &-header {
      padding: 17px 16px;
      font-size: 13px;
      font-weight: bold;
      border-bottom: 1px solid $box-grey;
      color: $lightest-grey;
    }

    &-item {
      padding: 13px 16px;
      font-size: 16px;
      font-weight: 300;
      color: $light-grey;
      display: inline-block;
      border-bottom: 1px solid $box-grey;
      cursor: pointer;
      width: 136px;

      &:hover {
        color: $iPlayer-Pink-Logo;
      }

      &.selected {
        background-color: $almost-black-alpha30;
        border-bottom: 2px solid $lightest-grey;
      }
    }
  }

  &__content {
    padding: 16px 100px 0px 100px;

    .Settings__header {
      font-size: 24px;
      line-height: 28px;
      font-weight: 300;
      color: $light-grey;
      margin-bottom: 24px;
    }

    .Settings__subheader {
      font-size: 20px;
      line-height: 24px;
      font-weight: 300;
      color: $lightest-grey;
      margin-bottom: 24px;
    }
  }
}
