@import "../../styles/base.scss";

.TvGuide_Schedule {
    display: flex;
    flex-direction: column;
    gap: 30px;

    &_Header{
        display: flex;
        padding: 8px 0px;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        background: $dark-grey;
        height: 50px;
        overflow: auto;

        &_Flex {
            display: flex;
            padding: 0px 20px;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            color: $white;
            text-align: center;
            font-family: $bbc-font;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            gap: 5px;
            cursor: pointer;

            &.selected, &:hover {
                color: $iPlayer-Pink-Logo;
            }

            &_Day {
                font-size: 13px;
            }

            &_Date {
                font-size: 14px;
            }
        }
    }

}

@media screen and (max-width: $breakpoint2) {
    .TvGuide_Schedule {
        &_Header {
            justify-content: flex-start;
            &_Flex {
                &_Day, &_Date {
                    font-size: 12px;
                }
            }
        } 
    }
}