@import "../../../styles/base.scss";

.PinInputModal {
  @include all-widths(465px);
  box-sizing: border-box;
  background-color: $white;
  font-family: $bbc-font;
  padding: 32px;

  &__title {
    color: $almost-black;
    font-size: 20px;
    font-weight: 300;
    position: relative;
    margin-bottom: 24px;
  }

  .PinInput {
    margin-bottom: 24px;

    input {
      border-color: $mid-grey;
    }
  }

  .Button {
    border-color: $mid-grey-2;
    @include all-widths(193px);
    float: none;
    display: inline-block;
    margin-bottom: 16px;

    &:first-of-type {
      margin-right: 15px;
    }
  }
}
