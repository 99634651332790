@import "../../styles/base.scss";

// Generic Button
.Button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: $iPlayer-Pink-Logo;
  color: $black;
  min-width: 144px;
  min-height: 48px;
  max-height: 48px;
  cursor: pointer;
  font-family: $bbc-font;
  font-size: 16px;
  font-weight: bold;
  border-width: 0;
  overflow: hidden;
  padding: 0;
  outline-color: transparent;
  outline-width: 0;
  transition: background-color 0.3s ease-in-out,
  color 0.3s ease-in-out;
  svg {
    path {
      transition: fill 0.3s ease-in-out;
      fill: $black;
    }
  }

  &:hover {
    background-color: $pink;
    color: $white;
    svg {
      path {
        fill: $white;
      }
    }
  }

  &.outlined {
    background-color: transparent;
    border: 1px solid $light-grey;
    color: $lightest-grey;

    &:hover {
      background-color: $white-alpha10;
    }

    &.selected {
      color: $black;
      background-color: $lightest-grey;
      svg {
        path {
          fill: $black;
        }
      }
      pointer-events: none;
    }
  }

  &.outline-curved{
    background-color: transparent;
    border: 1px solid $solid-white;
    color: $lightest-grey;
    border-radius: 6px;
  }

  &.singtel{
    &:hover{
      background-color: $singtel-hover-red;
      border : $singtel-border-red;
    }
  }
  &.starhub{
    &:hover{
      background-color: $starhub-hover-green;
      border: $starhub-border-green;
    }
  }


  &.disabled {
    background-color: transparent !important;
    border: 1px solid $mid-grey-2 !important;
    color: $mid-grey-2 !important;
    pointer-events: none;
  }

  &.selected {
    background-color: $dark-pink;
    color: $white;
    svg {
      path {
        fill: $white;
      }
    }
    pointer-events: none;

    // &:hover {
    //   background-color: $white;
    // }
  }

  &.white {
    border: solid 1px $dark-grey;
    background-color: $white;
    color: $dark-grey;
    float: right;

    &:hover {
      background-color: $light-grey;
    }
  }

  &.play {
    span {
      position: relative;
      left: -5px;
    }

    &.small {
      @include all-widths(48px);
      background-color: $iPlayer-Pink-Logo;
      border: none;
      margin: 0;

      span {
        display: none;
      }

      &:hover {
        background-color: $iPlayer-Pink-Logo;
      }
    }
  }


  &.close {
    span {
      position: relative;
      left: -5px;
    }
    background: none;

    &.small {
      @include all-widths(48px);
      background-color: $iPlayer-Pink-Logo;
      border: none;
      margin: 0;

      span {
        display: none;
      }

      &:hover {
        background-color: wheat;
      }
    }
  }

  &.check {
    svg {
      display: none;
    }

    &.selected {
      max-width: 99px;
      svg {
        height: 28px;
        display: inline-block;
        path {
          fill: $success-green;
        }
      }
      span {
        position: relative;
        left: 5px;
      }
    }
  }

  &.lock {
    border: solid 1px $dark-grey;
    background-color: $white;
    color: $dark-grey;
    float: right;

    > span, > svg {
      position: relative;
      left: -10px;
    }
    svg {
      height: 46px;
      display: inline-block;
      path {
        fill: $almost-black;
      }
    }

    &:hover {
      background-color: $light-grey;
    }
  }
}

.unifi-button-icon{
  width: 6em;
  height: 2em;
}

.astro-button-icon{
  width: 6em;
  height: 2em;
}

.unifi-button{
  &:hover{
    background-color: #f47f32;
    border: #f47f32;;
  }
}
.astro-button{
  &:hover{
    background-color: #ec008c;
    border: #ec008c;
  }
}

@media screen and (min-width: $breakpoint2) {
  .Button {
    max-width: 144px;
  }
}

// Toggle Button
.ToggleButton {
  display: flex;
  align-items: center;

  > img {
    display: inline-block;
    margin-right: 16px;
    cursor: pointer;
  }
  span {
    font-family: $bbc-font;
    font-size: 16px;
    font-weight: 300;
    color: $light-grey;
  }

  &.on {
    transition: 0.3s ease-in-out;
  }
}
