@import "../../../styles/base.scss";

.SettingsSuccessModal {
  box-sizing: border-box;
  @include all-widths(328px);
  height: 182px;
  background-color: $white;
  font-family: $bbc-font;
  padding: 34px 32px;

  > span {
    color: $dark-grey;
    font-size: 20px;
    font-weight: 300;
    position: relative;
  }

  svg {
    height: 14px;
    margin-right: 5px;
    display: inline-block;
    path {
      fill: $success-green;
    }
  }

  button {
    margin-top: 16px;
  }
}
