@import "../../styles/base.scss";

.ImageWordButton {
    background: inherit;
    border: none;
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin: 20px 0px 0px 0px;
    padding: 0;
    cursor: pointer;

    &:hover {
        svg {
            g {
                path {
                    fill: $iPlayer-Pink-Logo !important;
                }
            }
        }
        span {
            color: $iPlayer-Pink-Logo;
        }
    }

    span {
        padding-top: 1px;
        color: $lightest-grey;
        text-align: center;
        font-family: $bbc-font;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;

    }

    &.selected {
        svg {
            g {
                path {
                    fill: $iPlayer-Pink-Logo !important;
                }
            }
        }
        span {
            color: $iPlayer-Pink-Logo;
        }
        &.border {
            border: 1px solid $iPlayer-Pink-Logo;
        }
    }

    &.border {
        display: inline-flex;
        width: 177px;
        height: 40px;
        border: 1px solid $lightest-grey;
        align-items: center;
        justify-content: center;

        &:hover {
            border: 1px solid $iPlayer-Pink-Logo;
        }
    }
}