@import "../../styles/base.scss";

.PinInput {
  width: max-content;

  input {
    padding: 7px 8px;
    font-size: 24px;
    font-family: $bbc-font;
    color: $almost-black;
    display: inline-block;
    margin-right: 16px;
    max-width: 50px;
    text-align: center;
    border: 1px solid $mid-grey;

    &:last-of-type {
      margin-right: 0;
    }

    // The following styles removes the arrows from numerical inputs

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Firefox */
    &[type=number] {
      -moz-appearance:textfield;
    }
  }
}
