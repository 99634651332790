@import "../../styles/base.scss";

.Grid {
  padding: 22px $spacing-large 22px $spacing-large;
  background-color: $almost-black;
  font-family: $bbc-font;
  color: $light-grey;
  position: relative;
  border-bottom: 1px solid $divider-grey;

  @extend %generic-link;

  &__header {
    font-size: 32px;
    font-weight: 300;
    color: $lightest-grey;
    margin-bottom: 16px;
    cursor: default;
  }

  &__sorter {
    display: flex;
    margin-bottom: 24px;
    align-items: center;

    &-header {
      font-size: 16px;
      display: inline-block;
      margin-right: 16px;
      position: relative;
      cursor: default;
      white-space: nowrap;
    }

    &-menu {
      width: 227px;
      height: 48px;

      div:last-child {
        width: 227px;
        &:focus {
          outline: none;
        }

        li {
          outline: none;
        }
      }

      &-label {
        font-size: 16px;
        width: 100%;
        text-align: left;

        svg {
          float: right;
          width: 16px;
          height: 13px;
          fill: $light-grey;
          margin-top: 10px;
          position: relative;
          transform: rotate(90deg);
        }
      }

      button {
        width: 100%;
        height: 100%;
        border: solid 1px $light-grey !important;
        background-color: $almost-black !important;
        border-radius: unset !important;

        span {
          > span {
            display: none;
          }
          > div {
            font-weight: normal;
            font-family: $bbc-font;
          }
        }
        span > span {
          display: none;
        }

        &:focus {
          outline: none;
        }

        &:hover {
          transform: none !important;
        }
      }

      [role="menu"] {
        top: -48px;

        > ul {
          border: solid 1px $light-grey !important;
          background-color: $almost-black !important;
          border-radius: unset !important;
          font-weight: normal;
          padding: 0;

          li {
            padding: 6px 1rem;
            line-height: 34px;

            &:hover {
              transform: none !important;
              background-color: $mid-grey-2 !important;
            }

            &:not(hover) {
              color: $white;
            }

            svg {
              float: right;
              width: 16px;
              height: 13px;
              fill: $light-grey;
              margin-top: 10px;
              position: relative;
              transform: rotate(-90deg);
            }
          }
        }
      }
    }
  }

  &__items {
    position: relative;

    &-content {
      position: relative;
      display: grid;
      grid-row-gap: 24px;
      grid-column-gap: 16px;
      grid-template-columns: repeat(4, 1fr);

      .Tile {
        width: 100%;
      }
    }
  }
}

@media screen and (min-width: $breakpoint3) and (max-width: $breakpoint4) {
  .Grid {
    &__sorter {
      width: 237px;
    }
  }
}

@media screen and (max-width: $breakpoint3) {
  .Grid {
    padding: $spacing-small;

    &__header {
      font-size: 24px;
    }

    &__sorter-menu {
      flex-grow: 1;
    }

    &__items {
      &-content {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 8px;
        grid-row-gap: 16px;
      }
    }
  }
}
