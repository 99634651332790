@import "../../../styles/base.scss";

.ListItem {
  display: inline-grid;
  font-family: $bbc-font;
  white-space: normal;
  border-bottom: 1px solid $dark-grey;
  padding: $spacing-large 0;
  grid-template-columns: 300px auto;
  grid-template-rows: auto auto auto auto;
  grid-gap: 16px;
  row-gap: 0;
  min-width: 100%;

  &:last-of-type {
    border-width: 0;
  }

  &__image {
    grid-column: 1;
    grid-row: 1/6;
    height: calc(9/16 * 300px);
    position: relative;
    display: inline-block;
    cursor: pointer;
    overflow: hidden;

    > img {
      max-height: 100%;
      min-height: 100%;
      min-width: 100%;
    }
  }
  &__play-button {
    position: absolute;
    bottom: 0;
    left: 0;
    outline: none;
  }
  &.in-progress &__play-button {
    bottom: 6px;
  }

  &__header {
    grid-column: 2;
    grid-row: 1;
    font-size: 20px;
    font-weight: bold;
    color: $light-grey;
    cursor: pointer;

    &:hover {
      color: $iPlayer-Pink-Logo;
    }
  }

  &__subheader {
    grid-column: 2;
    grid-row: 2;
  }
  &__duration {
    display: inline-block;
    font-size: 13px;
    color: $mid-grey-2;
    margin: 5px 0px;
  }
  &__rating {
    display: inline-block;
    height: 16px;
    font-size: 13px;
    color: $mid-grey-2;
    line-height: 1.24;
    text-align: center;
    margin-left: 15px;
    border: 1px solid $mid-grey-2;
    padding: 2px 3px 0px 3px;
  }
  &__hd {
    display: inline-block;
    padding: 2px 3px 0px 3px;
    height: 16px;
    font-size: 13px;
    font-weight: bold;
    color: $dark-grey;
    background-color: $mid-grey-2;
    line-height: 1.3;
    text-align: center;
    margin-left: 5px;
    border: 1px solid $mid-grey-2;
  }

  &__description {
    grid-column: 2;
    grid-row: 3;
    font-size: 16px;
    color: $light-grey;
    margin-bottom: 5px;
  }

  &__meta {
    grid-column: 2;
    grid-row: 4;
    font-size: 13px;
    color: $mid-grey-2;
  }

  .ProgressBar {
    > div {
      background-color: $pink;
    }
  }
}


@media screen and (min-width: $breakpoint4) and (max-width: $breakpoint5) {
  .ListItem {
    grid-template-columns: 320px auto;

    &__image {
      height: calc(9/16 * 320px);
    }
  }
}

@media screen and (min-width: $breakpoint3) and (max-width: $breakpoint4) {
  .ListItem {
    grid-template-columns: 295px auto;

    &__image {
      height: calc(9/16 * 295px);
    }
  }
}

@media screen and (max-width: $breakpoint3) {
  .ListItem {
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 170px auto;
    padding: 12px 0 18px 0;

    &__image {
      height: calc(9/16 * 170px);
      grid-row: 1/4;
    }

    &__header {
      grid-row: 1;
      font-size: 18px;
    }

    &__subheader {
      grid-row: 2;

      > div {
        font-size: 14px;
      }
    }

    &__description {
      grid-column: 1/3;
      grid-row: 4;
      margin-top: 16px;
    }

    &__meta {
      grid-column: 1/3;
      grid-row: 5;
    }
  }
}

@media screen and (max-width: $breakpoint2) {
  .ListItem {
    grid-template-columns: 145px auto;

    &__image {
      height: calc(9/16 * 145px);
      grid-row: 1/4;
    }
  }
}
