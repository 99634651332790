@import "../../../styles/base.scss";

.PinManagement {
  input {
    padding: 7px 8px;
    font-size: 24px;
    font-family: $bbc-font;
    color: $almost-black;
  }

  .next-button {
    margin-top: 24px;
  }

  .select-button {
    display: block;
    margin-bottom: 17px;
    @include all-widths(404px);
  }

  .menu-button {
    display: block;
    margin-bottom: 17px;
    @include all-widths(320px);
  }

  &__rating {
    display: flex;

    .rating-button, .change-rating-button {
      min-width: 155px;
      width: 155px;
      margin-right: 10px;
    }
  }
}

.AnswerSecurity {
  input {
    width: 324px;
    padding: 9.5px 8px;
    font-size: 20px;
  }
}

.SelectSecurity {
  &__questions {
    button {
      padding: 0 16px;
      text-align: left;
      min-height: 60px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.SetRatingLevel {
  &__ratings {
    display: flex;

    button {
      margin-right: 16px;
      min-width: 100px;
    }
  }
  .next-button {
    @include all-widths(236px);
  }
}

.CheckSecurityQuestion {
  font-family: $bbc-font;
  color: $lightest-grey;

  &__question {
    font-weight: bold;
    font-size: 18px;
    max-width: 342px;

    > div:first-child {
      white-space: nowrap
    }

    input {
      margin-top: 8px;
      width: 324px;
      padding: 9.5px 8px;
      font-size: 20px;
    }
  }

  &__forgot {
    display: inline-block;

    > div:first-child {
      font-weight: bold;
    }
    > div:last-child {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__actions {
    display: flex;
    margin-top: 24px;

    .Button {
      margin: 0 17px 0 0;
    }
  }
}

.ChangePin, .TurnOffPin, .AddPin {
  .Button {
    @include all-widths(236px);
    display: flex;

    &:first-of-type {
      margin-top: 24px;
    }

    &:last-of-type {
      margin-top: 16px;
    }
  }
}
