@import "../../styles/base.scss";

.SettingsError {
  justify-items: center;
  font-family: $bbc-font;
  font-size: 16px;
  color: $white;
  margin-top: 8px;

  &__arrow {
    margin: 0 auto;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid $red;
  }

  &__message {
    background-color: $red;
    padding: 8px 8.5px;

    div:first-child {
      font-weight: bold;
    }
    div:last-child {
      font-weight: normal;
    }
  }
}
