@import "../../styles/base.scss";

.MenuTab {
  display: flex;
  align-items: center;
  padding: $spacing-large;
  border-left: 1px solid $divider-grey;
  color: $white;
  position: relative;
  font-family: $bbc-font;
  font-size: 14px;
  transition: color 0.5s ease-in,0.5s ease-out;
  
  &, > span, > img, >svg {
    cursor: pointer;
  }

  &:last-child {
    border-right: 1px solid $divider-grey;
  }

  &:hover {
    color: $iPlayer-Pink-Logo;
    transition: color 0.5s ease-in,0.5s ease-out;
    svg {
      path {
        transition: fill 0.3s ease-in-out;
        fill: $iPlayer-Pink-Logo;
      }
    }
  }

  &_toggle {
    color: $pink;
  }

  &__arrow {
    font-size: 6px;
    margin-left: 6px;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 6px;
  }

  &.selected {
    color: $pink;
    svg {
      path {
        transition: fill 0.3s ease-in-out;
        fill: $iPlayer-Pink-Logo;
      }
    }
  }
}

@media screen and (min-width: $breakpoint2) {
  .scroll-up {
    .MenuTab:hover {
      background-color: $box-grey-alpha85;
    }
  }
}

@media screen and (min-width: $breakpoint2) and (max-width: $breakpoint3) {
  .MenuTab {
    padding: $spacing-small;
  }
}

@media screen and (max-width: $breakpoint2) {
  .MenuTab {
    padding: 14px 16px;

    &.selected {
      color: $pink;
    }
  }
}
