@import "../../styles/base.scss";

$height: 32px;
$width: 254px;
$icon-size: 32px;

$breakpoint3-width: 380px;
$breakpoint4-width: 514px;
$breakpoint5-width: 722px;

@mixin focused($width) {
  .SearchBar.focused {
    width: $width;
    transition: width 0.05s ease-out;

    .SearchBar__input {
      width: $width - $icon-size;
      transition: width 0s linear 0.1s;

      > input {
        max-width: $width - (2 * $icon-size) - 14px;
      }
    }

    .SearchBar__icon {
      right: $icon-size;
      svg {
        path {
          fill: $black;
        }
      }
    }

    .SearchBar__close {
      width: 32px;
    }
  }
}

.SearchBar {
  width: $width;
  height: $height;
  border-radius: 2px;
  text-align: left;
  position: relative;

  > div {
    display: inline-block;
  }

  .SearchBar__input {
    @include equal-dimensions(100%);
    margin-bottom: 0;
    text-align: left;
    background-color: $white;
    overflow: hidden;

    > input {
      font-size: 16px;
      font-family: $bbc-font;
      padding: 7px;
      height: 32px;
      box-sizing: border-box;
      color: $black;
      max-width: $width - $icon-size - 14px;
      border-width: 0;
      outline: none;
      &::placeholder {
        font-family: $bbc-font;
        color: $black;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
      }
    }
  }

  .SearchBar__icon {
    position: absolute;
    right: 0;
    top: 0;
    @include equal-dimensions($icon-size);
    cursor: pointer;

    svg {
      @include equal-dimensions(16px);
      padding: 8px !important;
      path {
        fill: $black;
      }
    }
  }

  .SearchBar__close {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    @include equal-dimensions($icon-size);
    overflow: hidden;
    width: 0;

    svg {
      @include equal-dimensions(16px);
      padding: 8px !important;
    }
  }
}

@media screen and (max-width: $breakpoint2) {
  .SearchBar.focused {
    padding: $spacing-small;
    background-color: $almost-black;
    top: 52px;
    height: auto;
    box-sizing: border-box;

    .SearchBar__input {
      transition: none;
      width: 100%;
      height: 32px;

      > input {
        max-width: none;
      }
    }

    .SearchBar__close {
      display: none;
    }

    .SearchBar__icon {
      right: 8px;
      top: 8px;
      svg {
        path {
          fill: $black;
        }
      }
    }
  }
}

@media screen and (min-width: #{$breakpoint2 + 1px}) and (max-width: $breakpoint4) {
  @include focused($breakpoint3-width);
}

@media screen and (max-width: $breakpoint4) {
  .SearchBar {
    width: 32px;

    .SearchBar__input {
      overflow: hidden;
      width: 0px;
    }

    .SearchBar__icon {
      svg {
        path {
          fill: $white;
        }
      }
    }
  }
}

@media screen and (min-width: $breakpoint4) and (max-width: $breakpoint5) {
  @include focused($breakpoint4-width);
}

@media screen and (min-width: $breakpoint5) {
  @include focused($breakpoint5-width);
}
