@import "../../styles/base.scss";

.Modal {
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $almost-black-alpha90;
    z-index: 100000;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__close {
    position: absolute;
    top: $spacing-large;
    right: $spacing-large;
    cursor: pointer;
  }
}

@media screen and (max-width: $breakpoint2) {
  .Modal__close {
    top: $spacing-small;
    right: $spacing-small;
  }
}
