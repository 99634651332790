@import "../../styles/base.scss";

#Error__portal {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100001;
  background-color: $almost-black;
  width: 100%;
  height: 100%;
  display: none;
  align-items: center;
}

.error #Error__portal {
  display: flex;
}

.Error {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;

  &__contents {
    text-align: center;
    position: relative;
    max-width: 900px;
    top: 25%;
    padding: 0 16px;
  }

  svg {
    height: 100px;
    width: 100px;
  }

  &__message {
    font-weight: bold;
    font-size: 24px;
    color: $white;
    line-height: 28px;
    margin: 0 auto 24px auto;
    text-align: center;
  }
}

@media screen and (max-width: $breakpoint4) {
  .Error__contents {
    max-width: 650px;
  }
}

@media screen and (max-width: $breakpoint2) {
  .Error {
    &__contents {
      max-width: 350px;
      padding: 30px;
    }

    &__message {
      font-weight: bold;
      font-size: 20px;
    }
  }
}
