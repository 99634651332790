@import "../../styles/base.scss";

.ProgressBar {
  position: absolute;
    bottom: 0;
    width: 100%;
    height: 6px;
    background-color: $lightest-grey;

    > div {
      height: 100%;
      background-color: $pink;
      transition: background-color 0.5s ease-in,0.5s ease-out;
    }
}
