@import "../../styles/base.scss";

.Checkbox {
  display: inline-flex;
  height: 48px;
  width: 45px;
  border: 1px solid $almost-black;
  cursor: pointer;
  overflow: hidden;

  svg {
    margin: auto;
    width: 28px;
    height: 28px;
    display: inline-block;
    path {
      fill: $almost-black;
    }
  }
}
