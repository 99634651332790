@import "../../styles/base.scss";

.SubMenu {
  max-height: 0;
  width: 100%;
  background-color: $dark-grey;
  display: flex;
  justify-content: center;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out,top 0.5s ease-in-out;

  &__content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(113px, 1fr));
  }
  &.open {
    max-height: 64px;
  }

  &.live {
    width: 100%;
    background-color: $dark-grey;
    display: flex;
    justify-content: center;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out,top 0.5s ease-in-out;
    max-height: 300px;

  }
}

.scroll-down {
  .SubMenu {
    top: -71px;
  }
}

.scroll-up {
  .SubMenu {
    top: 71px;
    z-index: 1000;
    position: fixed;
  }
}

@media screen and (max-width: $breakpoint2) {
  .SubMenu {
    position: fixed;
    z-index: 1000;
    &.open, &__live {
      top: 0 !important;
      height: 100vh !important;
      max-height: 100vh !important;
    }
  }

  .scroll-up, .scroll-down {
    .SubMenu.open {
      position: fixed;
      background-color: $dark-grey;
    }
  }
}

@media screen and (min-width: $breakpoint2) and (max-width: $breakpoint3) {

  .SubMenu {
    background-color: $dark-grey;
    &.open, &__live {
      top: 51px !important; 
    }


    &__content {
      min-width: 100%;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    }
  }

  .scroll-up {
    .SubMenu {
      position: fixed;
      top: 51px;
    }
  }

  .scroll-down {
    .SubMenu {
      position: absolute;
      top: 51px;
    }
  }
}

@media screen and (min-width: $breakpoint3) {
  .scroll-down {
    .SubMenu {
      position: absolute;
      top: 71px;
    }
  }
}

@media screen and (min-width: $breakpoint3) {
  .SubMenu {
    &__content {
      grid-gap: 10px;
      max-width: $breakpoint5;
    }
  }
}
