@import "../../../styles/base.scss";

.SignInModal {
    box-sizing: border-box;
    font-family: BBCReithSans, sans-serif;
    padding: 34px 32px;
    margin: 0 !important;
    flex-wrap: wrap;
    justify-content: space-between;

  &__title {
    color: $lightest-grey;
    font-size: 20px;
    font-weight: 300;
    position: relative;
    top: -5px;
  }

  &__message {
    margin-top: 16px;
    margin-bottom: 24px;
    font-size: 16px;
    color: $lightest-grey;
  }

  &__shortmessage {
    margin-top: 16px;
    margin-bottom: 16px;
    font-size: 16px;
    color: $lightest-grey;
  }

  a {
    font-size: 16px;
    font-weight: bold;
    color: $lightest-grey;
  }

  button {
    max-width: 200px !important;
    width: 193px;
    height: 48px;
    margin-bottom: 23px;
    margin-right: 16px;
  }
}

.SigninLater {
  float: none !important;
  background-color: transparentize($color: white, $amount: 1) !important;
  color: $lightest-grey !important;
  border-color: $lightest-grey !important;
  
}

@media screen and (max-width: $breakpoint2) {
  .SignInModal button {
    display: block;
    margin: 0 auto 16px auto;
  }
}

.SignInSingtel {
  &__buttons {
    margin: 32px 0;

    button {
      @include all-widths(158px);
      @include all-heights(78px);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;
    }
  }

  a, a:visited, a:hover, a:active {
    color: $white;
    font-family: $bbc-font;
    font-weight: bold;
    font-size: 16px;
  }
}

.SignInMalaysia{
  &__buttons {
    margin: 32px 0;
  
    button {
      @include all-widths(158px);
      @include all-heights(78px);
      align-items: center;
      justify-content: center;
      display: inline-flex;
      margin-right: 16px;
    }
  }
}

.close-button{
  position: absolute;
  top : 24px;
  right: 0px;
}

@media screen and (max-width: $breakpoint2) {
  .SignInOverlay__buttons {

    button {
      display: block;
      margin: 0 auto 16px auto;

    }
  }
}

