@import "../../../../styles/base.scss";

.vjs-endscreen {
  .NextEpisode {
    padding: 24px;
    position: absolute;
    bottom: 0;
    height: 258px;
    width: 100%;
    background-color: $black-alpha85;
    font-family: $bbc-font;
    display: grid;
    grid-template-columns: 48px auto;
    grid-template-rows: auto 169px;
    grid-column-gap: 40px;

    &__header {
      color: $white;
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 16px;
      width: 100%;
      grid-row: 1;
      grid-column: 1/3;
    }

    .CountdownTimer {
      grid-row: 2;
      grid-column: 1;
      display: inline-block;
      position: relative;
      top: 30px;
    }

    &__item {
      grid-row: 2;
      grid-column: 2;
      display: grid;
      grid-template-columns: 300px auto;
      grid-gap: 19px;
    }

    &__image {
      grid-row: 1;
      grid-column: 1;
      position: relative;
      cursor: pointer;

      > img:first-of-type {
        width: 100%;
      }
      > img:last-of-type {
        position: absolute;
        left: 0;
        bottom: 0;
        @include equal-dimensions(48px);
        background-color: $iPlayer-Pink-Logo;
        opacity: 0.9;
      }
    }

    &__meta {
      grid-row: 1;
      grid-column: 2;
      font-family: $bbc-font;
      color: $white;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;

      > div {
        width: 100%;
        display: block;
      }

      &-series {
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 8px;
        cursor: pointer;
      }

      &-episode {
        font-weight: bold;
        font-size: 15px;
        line-height: 20px;
      }

      &-description {
        font-size: 15px;
        line-height: 18px;
        max-width: 510px;
      }
    }
  }

  .CountdownTimer {
    width: 48px;

    svg, div {
      display: inline-block;
    }

    .CircularProgressbar {
      position: initial;
      margin-bottom: 8px;
      width: 100%;
      height: 48px;

      &-path {
        stroke: $iPlayer-Pink-Logo;
      }

      &-trail {
        stroke: $light-grey;
      }

      &-text {
        font-family: $bbc-font;
        fill: $lightest-grey;
        font-size: 30px;
        font-weight: bold;
      }
    }

    &-cancel {
      color: $white;
      font-size: 13px;
      cursor: pointer;
      width: 100%;
      text-align: center;

      &:hover {
        color: $iPlayer-Pink-Logo;
      }
    }
  }
}
