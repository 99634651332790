@import "../../../../styles/base.scss";

.vjs-content-rating {
  @include equal-dimensions(100%);
  z-index: 10000000000;
  position: absolute;
  top: 0;
  left: 0;
  background-color: $almost-black;
}

.ContentRatingOverlay {
  font-family: $bbc-font;
  color: $white;
  @include equal-dimensions(100%);
  top: 0;
  left: 0;
  position: absolute;

  &__poster {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;

    > img {
      height: 100%;
    }
  }

  &__tint {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $black-alpha80;
  }

  &__close {
    position: absolute;
    top: 24px;
    right: 21px;
    cursor: pointer;

    svg {
      @include equal-dimensions(20px);

      &:hover {
        path {
          fill: $iPlayer-Pink-Logo;
        }
      }
    }
  }

  &__rating-info {
    margin-bottom: 24px;
    margin-top: calc(100vh * 0.4);
    margin-left: calc(100vw * 0.1);
    display: flex;
    align-items: flex-start;
    position: relative;

    > div {
      display: inline-flex;
      margin-right: 21px;
    }
  }

  &__rating-name {
    border: 1px solid $white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    padding: 17px 21px 9px 21px;
  }

  &__rating-description {
    font-size: 20px;
    line-height: 24px;
    max-width: 484px;
  }

  button {
    position: relative;
    margin-right: 16px;
    @include all-widths(204px);

    &:first-of-type {
      margin-left: calc(100vw * 0.1);
    }
  }
}
