@import "../../../../styles/base.scss";

.PlayerHeaderBar {
  flex-basis: 100%;
  height: 70px;
  padding: 20px;

  &__header {
    text-align: center;
  }

  &__title {
    display: inline-block;
    font-family: $bbc-font;
    font-size: 16px;
    font-weight: bold;
    color: $white;
  }

  &__description {
    text-align: center;
    font-family: $bbc-font;
    font-size: 15px;
    color: $white;
    margin-top: 10px;
  }

  &__close-button {
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 21px;

    svg {
      @include equal-dimensions(20px);

      &:hover {
        path {
          fill: $iPlayer-Pink-Logo;
        }
      }
    }
  }
}
