@import "../../styles/base.scss";

$aspect-ratio: 464/1280;
$image-width-ratio: 824/1280;
$widescreen-ratio: 9/16;

$category-aspect-ratio: 540/1280;
$category-image-width-ratio: 960/1280;

.Hero {
  width: 100%;
  max-width: $breakpoint5;
  font-family: $bbc-font;
  background-color: $almost-black;
  position: relative;
  display: flex;
  align-items: center;

  &__meta {
    padding: $spacing-large;
    z-index: 10;

    > div {
      width: 405px;
    }

    &-category {
      font-size: 32px;
      line-height: 36px;
      color: $light-grey;
      font-weight: 300;
      margin-bottom: 16px;
    }

    &-header {
      font-size: 24px;
      color: $white;
      font-weight: bold;
      margin-bottom: 8px;
      line-height: 28px;
    }
    &-subheader {
      font-size: 13px;
      color: $light-grey;
      margin-bottom: 8px;
    }
    &-description {
      font-size: 14px;
      color: $white;
      margin-bottom: 16px;
      line-height: 18px;
    }
    &-buttons {
      display: grid;
      grid-template-columns: min-content min-content;
      grid-gap: 8px;
    }
  }

  &__image {
    padding-right: 16px;
    display: flex;

    &, div {
      height: 100%;
    }

    img {
      min-height: 100%;
      min-width: 100%;
      height: 100%;
      width: 100%;
    }

    &-hori-gradient {
      position: absolute;
      height: 100%;
      width: 15%;
      background: linear-gradient(90deg, $almost-black, transparent);
    }
  }

  &__border {
    height: 1px;
    background-color: $divider-grey;
    position: absolute;
    bottom: 0;
  }
}

@media screen and (min-width: $breakpoint2) and (max-width: $breakpoint4) {
  .categories {
    .Hero {
      padding-bottom: $category-aspect-ratio * 100%;

      &__image {
        width: $category-image-width-ratio * 100%;
      }
    }
  }
}
@media screen and (min-width: $breakpoint4) and (max-width: $breakpoint5) {
  .Hero__meta {
    > div {
      width: 315px;
    }
  }
}

@media screen and (max-width: $breakpoint5) {
  .Hero__meta-category {
    font-size: 32px;
  }
}

@media screen and (min-width: $breakpoint2) and (max-width: $breakpoint4) {
  .Hero__meta {
    > div {
      width: 260px;
    }

    &-header {
      max-height: 60px;
    }
  }
}

@media screen and (max-width: $breakpoint3) {
  .Hero {
    &__meta {
      padding: $spacing-small;
    }

    &__image {
      right: $spacing-small;
    }
  }
}

@media screen and (max-width: $breakpoint2) {
  .Hero {
    display: grid;
    grid-template-rows: auto auto;
    padding: 0;

    &__image {
      grid-row: 1;
      grid-column: 1;
      right: 0;
      height: $widescreen-ratio * 100vw;
      position: relative;

      &-hori-gradient {
        display: none;
      }

      &, img, div {
        width: 100%;
      }
    }

    &__meta {
      grid-row: 2;
      grid-column: 1;
      position: relative;
      left: 0;

      > div {
        width: auto;
      }

      &-header {
        font-size: 20px;
        max-height: 60px;
      }
      &-subheader {
        font-size: 14px;
      }
      &-description {
        font-size: 15px;
      }
      &-buttons {
        margin-bottom: $spacing-large;
        grid-template-columns: 1fr 1fr;

        button {
          max-width: none !important;
        }
      }
    }
  }

  .categories {
    .Hero {
      grid-template-rows: auto auto auto;

      &__meta {
        &-category {
          grid-row: 1;
          font-size: 24px;
          margin: 11px 0 8px $spacing-small;
        }

        grid-row: 3;
      }

      &__image {
        grid-row: 2;
      }
    }
  }
}
