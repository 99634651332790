@import "../../styles/base.scss";

$counterWidth: 56px;
$mobileCounterWidth: 50px;

.Ol {
  &.level1 {
    margin-left: $counterWidth * 1;
  }
  &.level2 {
    margin-left: $counterWidth * 2;
  }
  &.level3 {
    margin-left: $counterWidth * 3;
  }

  .Ol[class*="level"] {
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .Oli {
    display: flex;
    align-items: flex-start;
    font-family: $bbc-font;
    position: relative;

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }

    > div {
      display: inline;
    }

    &__counter {
      font-weight: bold;
      color: $lightest-grey;
      min-width: $counterWidth;
    }

    &.header {
      font-weight: bold;
    }
  }
}

@media screen and (max-width: $breakpoint2) {
  .Ol {
    &.level1 {
      margin-left: $mobileCounterWidth * 1;
    }
    &.level2 {
      margin-left: $mobileCounterWidth * 2;
    }
    &.level3 {
      margin-left: $mobileCounterWidth * 3;
    }

    .Oli {
      &__counter {
        min-width: $mobileCounterWidth;
      }
    }
  }
}
