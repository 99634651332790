@import "../../../styles/base.scss";

.EntitledModal {
  box-sizing: border-box;
  width: 488px;
  background-color: $white;
  font-family: $bbc-font;
  padding: 34px 32px;

  > div {
    color: $dark-grey;
    font-size: 20px;
    font-weight: 300;
    position: relative;
  }

  button {
    margin-top: 24px;
    @include all-widths(203px);
  }
}
