@import "../../styles/base.scss";

.WatchLive {
    width: 90px;
}

.TvGuideScheduleList {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    height: 1000px;
    scrollbar-width: thin;
    scrollbar-color: $light-grey $dark-grey;
}

.TvGuideSchedule {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    &.live {
        background: $dark-grey;
        border-top: 1px solid $iPlayer-Pink-Logo;
    }
}

.live-symbol {
    position: absolute;
    right: 16px;
    top: 11.102px;
    width: 60px;
    height: 15.299px;
}

.TvGuideScheduleItem {
    display: flex;
    padding: 24px 0px;
    align-items: flex-start;
    background: $almost-black;
    border-top: 1px solid $divider-grey;
    gap: 20px;

    &.live {
        background: $dark-grey;
    }

    &_Time {
        color: $white;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: $bbc-font;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    &_Details {
        display: flex;
        flex-direction: column;
        gap:5px;

        &.live {
            background: $dark-grey;
        }

    }

    &_ProgramTitle {
        color: $white;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: $bbc-font;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;

        &.live {
            font-size: 16px;

            span {
                cursor: pointer;
            }
            
            &:hover, &.selected {
                color: $iPlayer-Pink-Logo;
            }
        }
    
        &.first_programme {
            font-size: 16px;
        }
    }

    &_Divider {
        width: 20px;
        padding: 5px 0px;
    }

    &_Episode {
        width: 509px;
        height: 16px;
        color: $light-grey;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: $bbc-font;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; 
    }

    &_Synopsis {
        width: 484px;
        color: $white;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: $bbc-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; 

        &.live {
            font-size: 13px;
            color: $lightest-gray;
        }
    
        &.first_programme {
            font-size: 13px;
        }
    }

    &_PlayButton {
        background: inherit;
        border: none;
        display: flex;
        flex-direction: row;
        gap: 8px;
        margin: 20px 0px 0px 0px;
        padding: 0;

        &:hover {
            span {
                color: $iPlayer-Pink-Logo;
            }
        }

        span {
            color: $lightest-grey;
            text-align: center;
            font-family: $bbc-font;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;

        }

        &.selected {
            span {
                color: $iPlayer-Pink-Logo;
            }
        }
    }
}

@media screen and (max-width: $breakpoint2) {
    .TvGuideScheduleItem {
        flex-direction: column;
        gap: 5px;
        width: 100%;
        padding: 20px 0px;
        &_Time {
            font-size: 12px;
            line-height: 16px; 
        }

        &_Details {
            padding: 0px 20px;
        }

        &_ProgramTitle {
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
        }

        &_Episode {
            font-size: 12px;
            width: 250px;
        }
        
        &.live {
            border-top: 1px solid $iPlayer-Pink-Logo;
        }     
    }
}

@media screen and (min-width: $breakpoint4)  {
    .TvGuideScheduleList {
        &::-webkit-scrollbar {
            width: 5px;
        }
      
        &::-webkit-scrollbar-thumb {
            background: $divider-grey; 
            border-radius: 10px; 
        }
    
        &::-webkit-scrollbar-track {
            background: $lightest-gray; 
            border-radius: 10px; 
        }
    }
}

@media screen and (min-width: $breakpoint2) and (max-width: $breakpoint4) {
    .TvGuideScheduleItem {
        &_Time {
            font-size: 15px;
        }

        &_ProgramTitle {
            font-size: 18px;
        }

        &_Synopsis {
            font-size: 14px;
        }
    }
}

