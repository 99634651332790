@import "../../../styles/base.scss";

.ParentalLockModal {
  @include all-widths(465px);
  box-sizing: border-box;
  background-color: $white;
  font-family: $bbc-font;
  padding: 32px;
  opacity: 0.9;

  &__title {
    color: $almost-black;
    font-size: 20px;
    font-weight: 300;
    position: relative;
    top: -5px;
  }

  &__message {
    margin: 16px 0;
    font-size: 16px;
    color: $almost-black;
  }

  &__hide {
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    span {
      margin-left: 14px;
      font-weight: bold;
      font-size: 16px;
    }
  }

  a {
    font-size: 16px;
    font-weight: bold;
    color: $dark-grey;
  }

  button {
    max-width: 200px !important;
    width: 193px;
    height: 48px;
    margin-bottom: 23px;
  }
}
