@import "../../styles/base.scss";

.Header {
  width: 100%;
  height: 70px;
  background-color: $almost-black;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid $divider-grey;
  z-index: 1000;
  position: sticky;
  top: 0;
  transition: top 0.3s ease-out;
  overflow: hidden;

  &__content {
    display: grid;
    grid-template-columns: min-content min-content auto min-content;
    align-items: center;
    height: 100%;
  }

  &__logo {
    height: auto;
    padding: $spacing-large;
    grid-column: 1 / 2;
    svg {
      g {
        path {
          transition: fill 0.3s ease-in-out;
        }
      }
    }
    > a {
      display: flex;
    }
  }
  &__partner-logo {
    grid-column: 2 / 3;
    color: white;
    white-space: nowrap;
  }
  &__menu {
    grid-column: 3 / 4;
    margin-left: auto;
  }
  &__search {
    display: flex;
    align-items: center;
    padding: $spacing-large;
    margin-left: auto;
    grid-column: 4 / 5;
  }
}

.searching .Header {
  opacity: 1 !important;
  overflow: visible;

  .Header__content {
    grid-template-columns: min-content min-content auto;

    .Header__menu {
      display: none;
    }

    .Header__search {
      grid-column: 3 / 4;
    }
  }
}

.scroll-down {
  .Header {
    top: -70px;

  }
}

.scroll-up {
  .Header {
    top: 0px;
    background-color: $almost-black-alpha85;
  }
}

.searching {
  .Header {
    background-color: $almost-black;
  }
}

@media screen and (min-width: $breakpoint2) {
  .Header__logo:hover svg g path { 
    fill : $white;
  }
}

@media screen and (max-width: $breakpoint2) {
  .Header {
    overflow: initial;

    &__logo {
      &.hover svg g path { 
        fill : $white;
      }
    }

    &__partner-logo {
      display: none
    }
  }

  .searching .Header {
    .Header__logo, .Header__partner-logo {
      display: none;
    }
    .Header__search {
      grid-column: 1 / 5;
      width: 100%;
      padding: 0;
    }
  }
}

@media screen and (max-width: $breakpoint3) {
  .Header {
    height: 50px;

    &__logo {
      padding: $spacing-small;
    }
    &__search {
      padding: $spacing-small;
    }
  }
}

@media screen and (max-width: $breakpoint5) {
  .Header {
    &__content {
      min-width: 100% !important;
    }
  }
}

@media screen and (min-width: $breakpoint5) {
  .Header {
    &__content {
      min-width: $breakpoint5;
    }
  }
}
