@import "../../../styles/base.scss";

.play-button-boundary-circle {
  transition: transform 0.25s ease-in-out, 
              animation 0.25s ease-in-out, 
              background 0.25s ease-in-out;
  display: inline-flex !important;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  background: radial-gradient(circle, transparent 20%, $lightest-gray 80%);
  animation: zoomInZoomOut 0.25s ease-in-out;
  width: 100%;
    height: 100%;
}

.play-button-zoom-out {
  animation: zoomOut 0.25s ease-in-out;
}

@keyframes zoomOut {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.75);
  }
}

@keyframes zoomInZoomOut {
  0% {
    background: radial-gradient(circle, transparent 20%, $lightest-gray 80%);
    transform: scale(1);
  }
  50% {
    background: radial-gradient(circle, transparent 20%, $lightest-gray 80%);
    transform: scale(1.5);
  }
  100% {
    background: radial-gradient(circle, $lightest-gray 20%, transparent 80% );
    transform: scale(1);
  }
}



body.scroll-lock-player {
  overflow-y: hidden !important;
}

.Player {


  .video-js {
    height: 100%;
    width: 100%;
  }
}


@import "../../../styles/base.scss";
$defaultFill: rgb(204, 204, 204);

.Player {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $almost-black-alpha90;
  z-index: 1000;
  height: 100%;
  width: 100%;

  height: 100%;

  .video-js {
    width: 100%;
    height: 100%;
    background-color: $black;

    &.scrubbing {
      .Video-mid-block {
        opacity: 0;
      }
    }

    &.vjs-ended {
      .vjs-poster {
        display: block !important;
      }

      .vjs-control-bar {
        visibility: hidden !important;
      }
    }

    .vjs-error-display {
      display: none;
    }

    .vjs-dock-text {
      opacity: 0 !important;
    }

    .vjs-big-play-button {
      display: none;
    }

    &.vjs-user-active, &.vjs-user-inactive {
      .vjs-control-bar {
        &.inactive {
          opacity: 0 !important;
          transition: opacity 0.25s !important;
          cursor: none;

          .Video-mid-buttons {
            pointer-events: none;
          }
        }
      }
    }

    &.vjs-waiting, &.vjs-seeking {
      .Video-mid-block {
        visibility: hidden;
      }
    }

    .vjs-loading-spinner {
      border-color: transparent;
      opacity: 1;

      &::before, &::after {
        display: none !important;
      }

      > img {
        @include equal-dimensions(48px);
        animation: spin 1.5s infinite linear;
      }
    }

    .vjs-control-bar {
      height: 100%;
      flex-wrap: wrap;
      transform: none !important;
      background-image: linear-gradient(to top, $black, rgba(0, 0, 0, 0.4), $black);
      background-color: transparent;
      opacity: 1 !important;
      transition: opacity 0.25s;

      .rightside-controls {
        flex-wrap: nowrap;
        white-space: nowrap;
        right: 5px;
        bottom: 6px;
        position: absolute;
        height: 48px;
        display: flex;
        width: auto;

        > div, > button {
          display: inline-flex;
          align-items: center;
          width: min-content;
          outline: none;
          margin-left: 30px;
        }
      }

      .Video {
        &-header-bar {
          flex-basis: 100%;
          height: 70px;
          padding: 20px;
        }

        &-mid-block {
          width: 100%;
          height: 71%;
          text-align: center;
          pointer-events: none;

          .Video-mid-button-group {
            position: absolute;
            margin: auto;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100px;
            align-items: center;
            display: flex;
            justify-content: center;

            .Video-mid-buttons {
              cursor: pointer;
              pointer-events: auto;
              height: 100px;
              width: 100px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              transition: transform 0.5s ease-in-out;
              
              &:not(:first-of-type) {
                margin-left: 157px;
              }
              svg:hover {
                path {
                  fill: $iPlayer-Pink-Logo;
                }
              }

              &.backward-button, &.forward-button {
                svg {
                  height: 65px;
                }
              }

              &.play-button {
                position: relative;
                top: -7px;

                svg {
                  height: 50px;
                  width: 50px;
                }
              }
            }
          }
        }
      }

      .vjs-play-control {
        display: none;
      }
    }

    .vjs-volume-control {
      .vjs-volume-handle {
          width: 32px;
          height: 32px;
          right: -21px;
          top: -9px;
          position: absolute;
          display: block;
          background: url(../../../assets/images/rectangle.svg) no-repeat;
      }
    }

    .vjs-mute-control {
      outline: none;

      svg {
        path: {
          fill: $defaultFill;
        }
      }
      &:hover {
        > div {
          > svg {
            path {
              fill: $iPlayer-Pink-Logo;
            }
          }
        }
      }

      > div {
        > svg {
          top: 0px;
          left: 0px;
          width: 45px;
          height: 45px;
        }
      }

      .vjs-icon-placeholder {
        &:before {
          content: "" !important;
        }
      }
    }

    .vjs-volume-panel.vjs-volume-panel-horizontal {
      width: 163px !important;
      height: 48px;
      bottom: 0px;
      position: absolute;
    }

    .vjs-volume-control.vjs-control.vjs-volume-horizontal {
      width: 163px !important;
      transition: none !important;
      opacity: 1 !important;
      top: 7.5px !important;
      left: -7px !important;
    }

    .vjs-volume-panel-horizontal.vjs-slider-active {
      .vjs-mute-control {
        > div {
          > svg {
            path {
              fill: $iPlayer-Pink-Logo;
            }
          }
        }
      }
    }

    .vjs-volume-bar.vjs-slider-horizontal {
      width: 163px !important;
      height: 3px !important;
    }

    .vjs-slider-horizontal .vjs-volume-level {
      height: 3px !important;
    }

    .vjs-live-control {
      display: none;
    }

    .vjs-quality-menu-wrapper {
      display: none;
    }

    .vjs-progress-control {
      top: 0px !important;
      height: 4px !important;
      position: absolute;
      top: auto !important;
      flex-basis: 100%;
      bottom: 88px;
      margin-left: 14px;
      flex: 0 1 auto;
      width: calc(100% - 160px) !important;
    }

    .vjs-play-progress {
      .vjs-progress-handle {
        @include equal-dimensions(25px);
        position: absolute;
        top: -0.7rem;
        right: -18px;
        display: block;
        z-index: 3;
        background: url(../../../assets/images/ellipse.svg) no-repeat;
      }

      .vjs-time-tooltip {
        visibility: hidden;
      }
    }

    .vjs-mouse-display .vjs-time-tooltip {
      top: -3.7em;
    }

    .vjs-play-progress, .vjs-volume-level {
      background-color: $iPlayer-Pink-Logo;
    }

    .live-player {
      left: 40px;
      right: auto !important;
      bottom: 80px !important;
      font-family: $bbc-font;
      font-size: 14px;
      color: $light-grey;
    }

    .live-progress {
      display: inline-flex;
      left: 100px;
      gap: 35px;
      pointer-events: none;
    }

    .vjs-time-display {
      position: absolute;
      right: 14px;
      bottom: 70px;
      

      .vjs-time-control {
        display: inline-block;
        margin: 0 3px;
        padding: 0;
        font-family: $bbc-font;
        font-size: 14px;
        color: $mid-grey;

        &.vjs-current-time {
          color: $light-grey;
        }

        &.vjs-time-divider {
          @include all-widths(5px);
        }
      }
    }

    .vjs-picture-in-picture-control {
      display: none;
    }

    .vjs-control.vjs-subs-caps-button {
      height: 100%;
      margin-left: 35px;

      button {
        position: relative;
        top: 5px;
        outline: none;
        padding: 0;

        > span {
          display: none;
        }

        > div > div {
          position: relative;
          top: -3px;

          > svg {
            top: 6px;
            margin-left: 16px;
            width: 22px;
            height: 20px;
            position: relative;
          }
        }

        &:hover, &.selected {
          > div > div {
            color: $iPlayer-Pink-Logo;
            > svg {
              path {
                fill: $iPlayer-Pink-Logo;
              }
            }
          }
        }
      }

      .vjs-menu-content {
        z-index: 10;
        left: 0;

        .vjs-menu-item {
          border-radius: 0;
          height: 56px;
          width: 166px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $dark-grey;
          border-bottom: 1px solid $black;
          font-family: $bbc-font;
          color: $light-grey;
          font-weight: 300;
          outline: none;
          margin: 0;

          &.vjs-selected {
            background-color: $iPlayer-Pink-Logo;
            font-weight: normal;
            color: $white;
            text-shadow: none;
          }

          &:hover {
            background-color: $iPlayer-Pink-Logo;
            color: $white;
            text-shadow: none;
          }
        }
      }

      .vjs-texttrack-settings {
        display: none !important;
      }
    }

    .vjs-control.vjs-audio-button {
      height: 100%;
      margin-left: 35px;

      button {
        position: relative;
        outline: none;
        padding: 0;

        > span {
          display: none;
        }

        > div > div {
          position: relative;
          top: -3px;

          > svg {
            top: 5px;
            margin-left: 5px;
            width: 25px;
            height: 25px;
            position: relative;
          }
        }

        &:hover, &.selected {
          > div > div {
            color: $iPlayer-Pink-Logo;
            > svg {
              path {
                fill: $iPlayer-Pink-Logo;
              }
            }
          }
        }
      }

      .vjs-menu-content {
        z-index: 10;
        left: 0;

        .vjs-menu-item {
          border-radius: 0;
          height: 56px;
          width: 166px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $dark-grey;
          border-bottom: 1px solid $black;
          font-family: $bbc-font;
          color: $light-grey;
          font-weight: 300;
          outline: none;
          margin: 0;

          &.vjs-selected {
            background-color: $iPlayer-Pink-Logo;
            font-weight: normal;
            color: $white;
            text-shadow: none;
          }

          &:hover {
            background-color: $iPlayer-Pink-Logo;
            color: $white;
            text-shadow: none;
          }
        }
      }

      .vjs-texttrack-settings {
        display: none !important;
      }
    }

    .vjs-text-track-settings {
      height: 100%;
    }

    .vjs-fullscreen-control {
      height: 100%;

      .vjs-icon-placeholder {
        display: none;
      }

      svg {
        position: relative;
        top: 5px;

        path: {
          fill: $defaultFill;
        }
      }

      &:hover {
        > div {
          > svg {
            path {
              fill: $iPlayer-Pink-Logo;
            }
          }
        }
      }
    }

    .vjs-button {
      font-family: $bbc-font;
      font-size: 14px;
      color: $light-grey;
      cursor: pointer;
    }

    .vjs-next-button {
      text-align: left;
      height: 100%;

      svg {
        position: relative;
        top: 5px;
        margin-left: 10px;

        path {
          fill: $defaultFill;
        }
      }

      &:hover {
        > div {
          color: $iPlayer-Pink-Logo;
          > svg {
            path {
              fill: $iPlayer-Pink-Logo;
            }
          }
        }
      }
    }

    .vjs-endscreen {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to top, black, rgba(0, 0, 0, 0.4), black);
    }
  }
}

