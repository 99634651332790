@import "../../styles/base.scss";

.Carousel {
  padding: 22px $spacing-large;
  background-color: $almost-black;
  font-family: $bbc-font;
  color: $light-grey;
  position: relative;
  max-width: 100%;

  @extend %generic-link;

  &__header {
    font-size: 20px;
    line-height: 20px;
    font-weight: bold;
    margin-bottom: $spacing-large;
    display: inline-block;

    a:hover, a:active, a:focus {
      text-decoration: none;
    }

  }

  &__view-all {
    font-size: 16px;
    line-height: 20px;
    float: right;
    a {
      transition: color 0.3s ease-in-out;
      &:hover, &:active, &:focus {
        color: $dark-pink;
        text-decoration: none;
      }
    }
  }

  &__overflow-gradient {
    height: 100%;
    position: absolute;
    top: 0;
    left: 90%;
    right: -2px;
    background: linear-gradient(90deg, transparent, $almost-black);
  }

  &__items {
    position: relative;

    &-content {
      position: relative;
      white-space: nowrap;
      overflow: hidden;

      .Tile:not(:last-of-type) {
        margin-right: 8px;
      }
    }
  }

  .mobile & {
    .Carousel__items-content {
      overflow-x: auto;
    }

    .Scroller {
      display: none;
    }
  }
}

@media screen and (max-width: $breakpoint4) {
  .Carousel {
    padding: 16px $spacing-large;
  }
}

@media screen and (max-width: $breakpoint3) {
  .Carousel {
    padding: 16px $spacing-small;

    .Scroller {
      top: 27%;
      right: 0;
    }
  }
}

@media screen and (max-width: $breakpoint2) {
  .Carousel__header {
    font-size: 18px;
  }
}

@media screen and (max-width: $breakpoint1) {
  .Carousel {
    .Scroller {
      top: 15%;
      right: 0;
    }
  }
}
