@font-face {
  font-family: "BBCReithSans";
  src: local(BBCReithSans-Light),
    url("../assets/fonts/BBCReithSans_Lt.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: "BBCReithSans";
  src: local(BBCReithSans-Light),
    url("../assets/fonts/BBCReithSans_LtIt.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "BBCReithSans";
  src: url("../assets/fonts/BBCReithSans_Rg.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: "BBCReithSans";
  src: url("../assets/fonts/BBCReithSans_It.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "BBCReithSans";
  src: url("../assets/fonts/BBCReithSans_Bd.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: "BBCReithSans";
  src: url("../assets/fonts/BBCReithSans_BdIt.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-stretch: normal;
}
