@import "../../styles/base.scss";

.Border {
  height: 1px;
  position: absolute;
  bottom: 0;
  max-width: 1280px;
  width: 100%;
  left: 0;

  > div {
    background-color: $divider-grey;
    height: 100%;
    margin: 0 $spacing-large;
  }
}

@media screen and (max-width: $breakpoint3) {
  .Border > div {
    margin: 0 $spacing-small;
  }
}
