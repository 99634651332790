@import "../../styles/base.scss";

.BasicPage {
  padding: 30px 70px 30px $spacing-large;
  font-family: $bbc-font;
  color: $lightest-grey;

  .emphasis {
    text-decoration: underline;
    font-weight: bold;
  }

  &__header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 24px;
    line-height: 28px;
    text-transform: capitalize;
  }

  hr {
    border-color: $mid-grey;
    border-style: solid;
    border-width: 0.5px;
    margin-bottom: 24px;
  }

  a {
    color: $white;
    transition: color 0.3s ease-in-out;
    &:hover, &:focus {
      text-decoration: underline;
      color: $dark-pink;
    }
  }

  .Ol {
    margin-bottom: 32px;
  }
}

.Terms {
  .Oli {
    line-height: 24px;
    font-size: 16px;
  }
}

.FAQ {
  &__question {
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
  }

  .Oli {
    &__counter {
      font-size: 20px;
      line-height: 24px;
    }

    p, li {
      line-height: 20px;
      font-size: 16px;
      margin-bottom: 16px;
      color: $white;
    }
  }

  li {
    margin-bottom: 16px;
  }

  .requirement {
    margin-bottom: 10px;

    &-header {
      font-size: 12px;
      margin-bottom: 5px;
      font-weight: bold;
    }

    &-row {
      div {
        display: inline-block;
      }
      div:first-of-type {
        width: 300px;
      }
    }

    &-footnote {
      font-size: 12px !important;
      margin-bottom: 1px !important;
    }

    &:last-of-type {
      margin-bottom: 20px;
    }
  }
}

.About {
  color: $white;

  .BasicPage__header {
    margin-bottom: 8px;
  }

  &__subheader {
    font-size: 16px;
    font-weight: 300;
    font-style: italic;
    margin-bottom: 24px;
  }

  &__channels {
    text-align: center;
    margin: 16px 0 24px 0;
    display: grid;
    grid-template-columns: repeat(6, min-content);
    grid-gap: 8px;
    justify-content: center;

    > img {
      height: 48px;
    }
  }

  &__image {
    display: flex;
    justify-content: center;
    img {
      height: 222px;
    }
  }
}

@media screen and (max-width: $breakpoint2) {
  .BasicPage {
    padding: $spacing-large;

    &__header {
      font-size: 20px;
      line-height: 24px;
    }
  }

  .About {
    &__channels {
      grid-template-columns: repeat(3, min-content);
    }
    &__image {
      img {
        height: 179px;
      }
    }
  }

  .FAQ {
    &__question {
      font-size: 18px;
    }

    .Oli {
      &__counter {
        font-size: 18px;
        min-width: 30px;
      }
    }
  }

  .Terms {
    .Oli {
      &__counter {
        font-size: 14px;
        min-width: 50px;
      }
    }
  }
}
