@import "../../styles/base.scss";

.Footer {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;

  &__content {
    max-width: $breakpoint4;
    padding: 24px 0;
    position: relative;
  }

  &__item {
    display: inline-block;
    @extend %generic-link;
    padding: 4px 16px;
    font-family: $bbc-font;
    font-size: 14px;
    border-right: 1px solid $divider-grey;
    a {
      text-decoration: underline;
      transition: color 0.3s ease-in-out;
      &:hover, &:focus {
        color: $iPlayer-Pink-Logo;
      }
    }
  }

  &.menu {
    .Footer__content {
      padding: 4px 0 40px 0;
      min-width: auto;
      border-width: 0;
    }

    .Footer__item {
      display: block;
      border-width: 0;
      padding: 12px 16px;
    }
  }
}

@media screen and (max-width: $breakpoint5) {
  .Footer {
    &__content {
      min-width: 100%;
    }
  }
}

@media screen and (min-width: $breakpoint5) {
  .Footer {
    &__content {
      min-width: $breakpoint5;
    }
  }
}

@media screen and (max-width: $breakpoint2) {
  .Footer {
    &__content {
      padding: 4px 0 40px 0;
    }

    &__item {
      display: block;
      border-width: 0;
      padding: 12px 8px;
    }
  }
}
