@import "../../styles/base.scss";

.Channel {
  &__meta {
    padding: 17px $spacing-large 24px $spacing-large;
    display: grid;
    grid-template-columns: auto 120px;
    grid-template-rows: auto auto;
    font-family: $bbc-font;
    color: $lightest-grey;
    position: relative;

    &-name {
      grid-row: 1;
      grid-column: 1;
      font-size: 32px;
      margin-bottom: 8px;
      font-weight: 300;
    }

    &-description {
      grid-row: 2;
      grid-column: 1;
      font-size: 14px;
      max-width: 600px;
      max-height: 40px;
    }

    &-image {
      grid-row: 1 / 3;
      grid-column: 2;
      display: flex;
      align-items: center;

      img {
        max-width: 100%;
      }
    }
  }
}

@media screen and (min-width: $breakpoint3) and (max-width: $breakpoint4) {
  .Channel__meta {
    grid-template-columns: auto 125px;
  }
}

@media screen and (max-width: $breakpoint3) {
  .Channel__meta {
    grid-template-columns: auto 84px;
    grid-column-gap: 30px;
    padding: 17px $spacing-small 16px $spacing-small;

    &-name {
      font-size: 24px;
    }

    &-description {
      font-size: 15px;
      max-height: 80px;
    }

    &-image {
      align-items: flex-start;
    }
  }
}

@media screen and (max-width: $breakpoint3) {
  .Channel__meta {
    padding-top: $spacing-small;
  }
}
