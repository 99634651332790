@import "../../../styles/base.scss";

.MobileSubMenuItem {
  
  height: 54px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  gap:10px;
  justify-content: center;
  

  &__icon {
    width:80px;
  }

  &.selected {
    span {
      color: $iPlayer-Pink-Logo;
      transition: color 0.5s ease-in,0.5s ease-out;
      font-weight: 700;
    }
  }

  span {
    font-family: $bbc-font;
    color: $white;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    padding: 0px 12px;
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
  }

  div {
    margin-left: auto;
    padding-right: $spacing-large;

    img {
      height: 16px;
    }
  }
}

.MobileSubMenu__contents.images {
  .MobileSubMenuItem {
    width: auto;
    height: min-content;
    border-width: 0;
    margin: $spacing-small;

    &.selected {
      outline: 4.5px solid $lightest-grey;
    }
    span {
      display: none;
    }

    &__icon {
      margin: 0;
      display: block;
      width: 100%;
    }
  }
}
