@import "../../styles/base.scss";

.Overlay {
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $black url(../../assets/images/blue-background.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 100000;

    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    justify-content: center;
  }

  &__content {
    text-align: center;
    outline: none;
  }

  &__logo {
    svg {
      width: 237px;
      height: 39px;
    }
  }

  &__message {
    margin-top: 32px;
    font-family: $bbc-font;
    font-size: 28px;
    font-weight: 300;
    color: $white;
    padding: 0 16px;
    text-align: center;
  }
}

@media screen and (max-width: $breakpoint2) {
  .Overlay {
    &__logo {
      svg {
        width: 198px;
        height: 33px;
      }
    }
    &__message {
      font-size: 22px;
      margin-top: 22px;
    }
  }
}
