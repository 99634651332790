@import "../../../styles/base.scss";

.TrialProgressModal {
  @include all-widths(465px);
  box-sizing: border-box;
  background-color: $white;
  font-family: $bbc-font;
  padding: 34px 32px;

  &__message {
    margin-bottom: 24px;
    color: $dark-grey;
    font-size: 20px;
    font-weight: 300;
  }

  button {
    max-width: 200px !important;
    width: 193px;
    height: 48px;
  }
}
